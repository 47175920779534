import { TextField } from '@mui/material';
import { styled } from '@mui/system';
import { useState } from 'react';
import validator from 'validator';
import './CustomTextfield.css'

export enum CustomTexfieldKind{
    Any,
    Email,
    PostaCode,
    Phone
}

interface CustomTextfieldProps {
    label: string;
    id: string;
    required: boolean;
    type: CustomTexfieldKind;
    handleModelChange: Function
 }


const STextField = styled(TextField)`
  background: white;
`;

export default function CustomField(props: CustomTextfieldProps) {

    const [value, setValue] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const val = event.currentTarget.value;  
        switch(props.type) {
            case CustomTexfieldKind.Email:
                setIsValid(validator.isEmail(val));
                break;
            case CustomTexfieldKind.Phone:
                setIsValid(validator.isMobilePhone(val,'en-CA'));
                break;
            case CustomTexfieldKind.PostaCode:
                setIsValid(validator.isPostalCode(val,'CA'));
                break;
            default:
                setIsValid(!event.currentTarget.required || val !== "");
        }
        setValue(val);
        props.handleModelChange(props.id, val);
    }

    const handleOnBlur = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setIsDirty(true)
    }

    function isError() : boolean {
        if (value  === '') {
            return isDirty && props.required;
        }
        return isDirty && !isValid;
    }


    return (<STextField required={props.required} 
        error={isError()}    
        onBlur={(e) => handleOnBlur(e)}
        id={props.id} 
        label={props.label}
        variant='filled'
        onChange={(e) => handleChange(e)} />);

}