import { Stack, Typography } from '@mui/material';
import './CustomerFields.css';
import CustomField, { CustomTexfieldKind } from '../CustomTextfield/CustomTextfield';

interface CustomerFieldsProps {
    customerNumber: string;
    isMandatory: boolean;
    handleModelChange: Function;
  }

export default function CustomerFields(props: CustomerFieldsProps) {




/*
    const moveInValues: { id: number, name: string }[] = [
        { "id": 0, "name": "Faire un choix" },
        { "id": 1, "name": "Hiver 2023" },
        { "id": 2, "name": "Automne 2023" },
        { "id": 2, "name": "Année 2024" }
    ];*/

    return (
        <Stack sx={{ m: 4 }} spacing={4}>
            <Typography variant="h4">Client {props.customerNumber}</Typography>
            <CustomField handleModelChange={props.handleModelChange} type={CustomTexfieldKind.Any} required={props.isMandatory} id={`firstName${props.customerNumber}`} label={`Prénom client ${props.customerNumber}`} />
            <CustomField handleModelChange={props.handleModelChange} type={CustomTexfieldKind.Any} required={props.isMandatory} id={`lastName${props.customerNumber}`} label={`Nom client ${props.customerNumber}`} />
            <CustomField handleModelChange={props.handleModelChange} type={CustomTexfieldKind.Email} required={props.isMandatory} id={`emailAddress${props.customerNumber}`} label={`Courriel client ${props.customerNumber}`} />
            <CustomField handleModelChange={props.handleModelChange} type={CustomTexfieldKind.Any} required={props.isMandatory} id={`address${props.customerNumber}`} label={`Adresse client ${props.customerNumber}`} />
            <CustomField handleModelChange={props.handleModelChange} type={CustomTexfieldKind.Any} required={false} id={`apptNo${props.customerNumber}`} label={`# Appartement client ${props.customerNumber}`} />
            <CustomField handleModelChange={props.handleModelChange} type={CustomTexfieldKind.Any} required={props.isMandatory} id={`city${props.customerNumber}`} label={`Ville client ${props.customerNumber}`} />
            <CustomField handleModelChange={props.handleModelChange} type={CustomTexfieldKind.Any} required={props.isMandatory} id={`province${props.customerNumber}`} label={`Province client ${props.customerNumber}`} />
            <CustomField handleModelChange={props.handleModelChange} type={CustomTexfieldKind.PostaCode} required={props.isMandatory} id={`postalCode${props.customerNumber}`} label={`Code postal client ${props.customerNumber}`} />
            <CustomField handleModelChange={props.handleModelChange} type={CustomTexfieldKind.Phone} required={false} id={`phone${props.customerNumber}`} label={`Téléphone client ${props.customerNumber}`} />
            <CustomField handleModelChange={props.handleModelChange} type={CustomTexfieldKind.Phone} required={false} id={`officePhone${props.customerNumber}`} label={`Téléphone bureau client ${props.customerNumber}`} />
            <CustomField handleModelChange={props.handleModelChange} type={CustomTexfieldKind.Any} required={props.isMandatory} id={`birthDate${props.customerNumber}`} label={`Date naissance client ${props.customerNumber}`} />
            <CustomField handleModelChange={props.handleModelChange} type={CustomTexfieldKind.Any} required={props.isMandatory} id={`employer${props.customerNumber}`} label={`Employeur client ${props.customerNumber}`} />
        </Stack>
    );

    /*
        <Select
            labelId="form-move-in-label"
            id="form-move-in"
            label="1- Quand aimeriez-vous emménager dans votre prochaine maison jumelée?*"
        >
             {moveInValues.map(value => (  
                <MenuItem value={value.id}>{value.name}</MenuItem>
                ))}  
        </Select>*/
};