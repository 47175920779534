import logo from './patriote_logo.png';
import LoadingButton from '@mui/lab/LoadingButton';

import './App.css';
import React, { FormEvent, useState } from 'react';
import CustomerFields from './components/customerFields/CustomerFields';
import FillPDF, { FillPdfParams } from './services/FillPDF';


function App() {
    const queryParameters = new URLSearchParams(window.location.search);
    const paramDealId: string = queryParameters.get("dealDealId") || "";
    const paramPropLot: string = queryParameters.get("propLot") || "";
    const paramPropContractNo: string = queryParameters.get("propContractNo") || "";
    const paramPropAddressNo: string = queryParameters.get("propAddressNo") || "";
    const paramPropStreet: string = queryParameters.get("propStreet") || "";
    const paramPropCity: string = queryParameters.get("propCity") || "";
    const paramPropProv: string = queryParameters.get("propProv") || "";
    const paramPropPostalCode: string = queryParameters.get("propPostalCode") || "";
    const paramPropSurface: string = queryParameters.get("propSurface") || "";
    const paramPropDistrict: string = queryParameters.get("propDistrict") || "";
    const paramPropRecordNo: string = queryParameters.get("propRecordNo") || "";
    const paramPropCadastre: string = queryParameters.get("propCadastre") || "";

    let initModel:FillPdfParams = {
        dealId: paramDealId,
        firstName1: "", lastName1: "", emailAddress1: "", address1: "", city1: "", province1: "", postalCode1: "", apptNo1: "", 
        phone1: "", officePhone1: "", phoneExt1: "", birthDate1: "", employer1: "",
        firstName2: "", lastName2: "", emailAddress2: "", address2: "", city2: "", province2: "", postalCode2: "", apptNo2: "", 
        phone2: "", officePhone2: "", phoneExt2: "", birthDate2: "", employer2: "",
        propLot: paramPropLot, propContractNo: paramPropContractNo, propAddressNo: paramPropAddressNo, propStreet: paramPropStreet, 
        propCity: paramPropCity, propProv: paramPropProv, propPostalCode: paramPropPostalCode, propSurface: paramPropSurface, 
        propDistrict: paramPropDistrict, propRecordNo: paramPropRecordNo, propCadastre: paramPropCadastre
    };

    const [model, setModel] = useState(initModel);
    const [loading, setLoading] = React.useState(false);
    const [completed, setCompleted] = React.useState(false);
    const [error, setError] = React.useState(false);

    const handleModelChange = (field: string, value: string) => {
        //set it in the model
        setModel({
          ...model,
          [field]: value,
        });
    }


    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        FillPDF(model).then(
            response => {
                console.log(response);
                setLoading(false);
                setCompleted(true);
            })
        .catch(error => {
            console.log(error);
            setCompleted(true);
            setError(true);
        });
        return false;
    }

  if (completed && !error) {
    return (
        <div className="App">
        <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <h2>Merci d'avoir rempli le formulaire. Les documents seront acheminés à votre vendeur.</h2>
        </header>
        </div>
    )
  } else if (completed && error) {
    <div className="App">
    <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h2>Une erreur est survenue. Veuillez réessayer plus tard. Si l'erreur persiste, veuillez contacter votre vendeur.</h2>
    </header>
    </div>
  }
           
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h2>Veuillez s'il vous plaît, entrer vos informations dans le formulaire sécurisé.</h2>
      </header>
      <div className="content">

        <form onSubmit={onSubmit}>
        <div>
            <CustomerFields customerNumber="1" isMandatory={true} handleModelChange={handleModelChange} />
            <CustomerFields customerNumber="2" isMandatory={false} handleModelChange={handleModelChange} />
            <LoadingButton sx={{m: 3, backgroundColor: '#56839A', color: 'white'}} loading={loading} type='submit' variant="outlined">
            <span>Envoyer</span>
            </LoadingButton>
        </div>
        </form>
      </div>
    </div>
  );
}

export default App;
