

export interface FillPdfParams {
    dealId: string;
    firstName1?: string;
    lastName1?:  string;
    emailAddress1?: string;
    address1?: string;
    city1?: string;
    province1?: string;
    postalCode1?: string;
    apptNo1?: string;
    phone1?: string;
    officePhone1?: string;
    phoneExt1?: string;
    birthDate1?: string;
    employer1?: string;
    firstName2?: string;
    lastName2?:  string;
    emailAddress2?: string;
    address2?: string;
    city2?: string;
    province2?: string;
    postalCode2?: string;
    apptNo2?: string;
    phone2?: string;
    officePhone2?: string;
    phoneExt2?: string;
    birthDate2?: string;
    employer2?: string;
    propLot?: string;
    propContractNo?: string;
    propAddressNo?: string;
    propStreet?: string;
    propCity?: string;
    propProv?: string;
    propPostalCode?: string;
    propSurface?: string;
    propDistrict?: string;
    propRecordNo?: string;
    propCadastre?: string;
  }

  export default function FillPDF(params: FillPdfParams) {
    return fetch('https://questionnaire.solutionsctc.com/proxy/fillPDF', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
        });
  }